<template>
  <v-card>
    <v-toolbar
      dense
      card
    >
      <v-icon :color="mnpClass">
        {{ mnpIcon }}
      </v-icon>
      <v-toolbar-title>Add New MNP</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container
          fluid
          grid-list-xs
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <mnp-donors v-model="item.donor" />
            </v-flex>
            <v-flex xs12>
              <msisdn
                v-model="item.msisdn"
                :existing="false"
                :rules="msisdnRules"
                hint="Msisdn provided by the customer"
                label="Enter Msisdn"
                required
              />
            </v-flex>
            <v-flex xs12>
              <iccid
                v-model="item.iccid"
                :rules="iccidRules"
                hint="Locate serial number on the simpack"
                label="Enter serial number"
                required
                blank-sim
              />
            </v-flex>
            <v-flex xs12>
              <black-packages v-model="item.plan" />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        flat
        color="primary"
        @click.native="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        flat
        color="primary"
        @click.native="addRegistrationItem"
      >
        Add New MNP
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mnpClass, mnpIcon } from '@/utils'

export default {
  components: {
    BlackPackages: () => import(/* webpackChunkName: "blackPackages" */ '@/components/BlackPackages'),
    Iccid: () => import(/* webpackChunkName: "iccid" */ '@/components/Iccid'),
    MnpDonors: () => import(/* webpackChunkName: "mnpDonors" */ '@/components/MnpDonors'),
    Msisdn: () => import(/* webpackChunkName: "msisdn" */ '@/components/Msisdn'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    initialDonor: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      item: {
        // chargeable: 0.00,
        detail: null,
        donor: null,
        iccid: null,
        icon: null,
        iconClass: null,
        msisdn: null,
        plan: {},
        // sellingPrice: 0.00
      },
      msisdnRules: [val => !!val || 'Msisdn required!'],
      iccidRules: [val => !!val || 'Iccid required!'],
      maxWidth: '390px',
      mnpClass: mnpClass,
      mnpIcon: mnpIcon,
      valid: true,
    }
  },
  watch: {
    dialog: function (val) {
      this.item.detail = this.item.iccid = this.item.icon =
        this.item.iconClass = this.item.msisdn = null
      this.item.plan = {}
      this.$refs.form.reset()
      this.item.donor = this.initialDonor
    },
  },
  methods: {
    addRegistrationItem: function () {
      if (!this.$refs.form.validate()) return
      this.item.detail = this.item.msisdn + ', ' + this.item.plan.code +
        ', ' + this.item.donor
      this.item.icon = this.mnpIcon
      this.item.iconClass = mnpClass
      // this.item.chargeable = getNumeralFromBlackCode(this.item.plan.code)
      const localValue = this.value
      localValue.push(Object.assign({}, this.item))
      this.$emit('input', localValue)
      this.editIndex = -1
      this.closeDialog()
    },
    closeDialog: function () {
      this.$emit('update:dialog', false)
    },
  },
}
</script>
